@import '~flickity/dist/flickity.min.css';

.header-slider {
  &__item {
    width: 60%;

    @media (max-width: 767px) {
      width: 85%;
    }

    .archive-post {
      transition: all 0.3s ease-in-out;
    }

    &:not(.is-selected) {
      .archive-post {
        opacity: 0.6;
        transform: scaleX(0.9) scaleY(0.8);
      }
    }
  }
}
